import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, defaultIfEmpty, tap } from 'rxjs/operators';

import { Alert, AlertViewModel } from '@models';
import { SessionStorage, APP_CONFIGURATION } from '@app/index';

import { HttpService } from './http.service';
import { LocalStorage } from '@app/utilities';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private alertSubject: BehaviorSubject<AlertViewModel[]>;
  private dashboardAlertSubject: BehaviorSubject<AlertViewModel>;
  private hiddenAlerts: number[];
  private showDashboardSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private initialized: boolean;

  constructor(private httpService: HttpService) {
    this.alertSubject = new BehaviorSubject<AlertViewModel[]>(
      JSON.parse(
        SessionStorage.get(APP_CONFIGURATION.SESSION_STORAGE_KEYS.ALERTS)
      )
    );
    this.hiddenAlerts = JSON.parse(
      LocalStorage.get(APP_CONFIGURATION.LOCAL_STORAGE_KEYS.HIDDEN_ALERTS)
    );

    this.dashboardAlertSubject = new BehaviorSubject<AlertViewModel>(
      this.findDashboardAlert()
    );
  }

  private getAlerts(): Observable<AlertViewModel[]> {
    return this.httpService
      .get<Alert[]>('/api/v1/app/alerts', null, false)
      .pipe(
        map((alerts: Alert[]) => {
          const alertsVM: AlertViewModel[] = [];

          if (alerts && alerts.length > 0) {
            alerts.forEach((alert) => {
              alertsVM.push(new AlertViewModel(alert));
            });
          }

          const sortedAlerts = alertsVM
            .sort((a, b) =>
              new Date(a.timestamp) > new Date(b.timestamp) ? 1 : -1
            )
            .sort((a, b) => (!a.highPriority && b.highPriority ? 1 : -1));

          return sortedAlerts;
        }),
        tap((alerts) => {
          SessionStorage.set(
            APP_CONFIGURATION.SESSION_STORAGE_KEYS.ALERTS,
            JSON.stringify(alerts)
          );
        }),
        defaultIfEmpty([])
      );
  }

  private findDashboardAlert(): AlertViewModel {
    let dashboardAlert: AlertViewModel = null;
    if (this.alertSubject.value) {
      dashboardAlert = this.alertSubject.value.find(
        (alert) =>
          alert.highPriority &&
          alert.displayonDashboard &&
          (!this.hiddenAlerts || !this.hiddenAlerts.includes(alert.id))
      );
    }
    return dashboardAlert;
  }

  public alerts(refetch?: boolean): Observable<AlertViewModel[]> {
    if (refetch || !this.initialized) {
      this.initialized = true;
      this.getAlerts().subscribe((alerts) => {
        this.alertSubject.next(alerts);
        this.dashboardAlertSubject.next(this.findDashboardAlert());
      });
    }

    return this.alertSubject.asObservable();
  }

  public refreshAlerts(): void {
    this.alerts(true);
  }

  public showDashboard(): Observable<boolean> {
    return this.showDashboardSubject.asObservable();
  }

  public setShowDashboard(show: boolean) {
    this.showDashboardSubject.next(show);
  }

  public dashboardAlert(): Observable<AlertViewModel[]> {
    return new BehaviorSubject<AlertViewModel[]>(
      (this.alertSubject.value || []).filter((alert) => {
        return (
          alert.highPriority &&
          alert.displayonDashboard &&
          !this.hiddenAlerts.includes(alert.id)
        );
      })
    ).asObservable();
  }

  public hideDashboardAlert(id: number): void {
    this.hiddenAlerts = JSON.parse(
      LocalStorage.get(APP_CONFIGURATION.LOCAL_STORAGE_KEYS.HIDDEN_ALERTS)
    );
    if (!this.hiddenAlerts) {
      this.hiddenAlerts = [];
    }
    if (!this.hiddenAlerts.includes(id)) {
      this.hiddenAlerts.push(id);
      LocalStorage.set(
        APP_CONFIGURATION.LOCAL_STORAGE_KEYS.HIDDEN_ALERTS,
        JSON.stringify(this.hiddenAlerts)
      );
    }

    this.dashboardAlertSubject.next(null);
  }
}
